// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-services-catalogue-js": () => import("./../../../src/pages/services/catalogue.js" /* webpackChunkName: "component---src-pages-services-catalogue-js" */),
  "component---src-pages-services-corporate-js": () => import("./../../../src/pages/services/corporate.js" /* webpackChunkName: "component---src-pages-services-corporate-js" */),
  "component---src-templates-adhd-landing-page-js": () => import("./../../../src/templates/adhd-landing-page.js" /* webpackChunkName: "component---src-templates-adhd-landing-page-js" */),
  "component---src-templates-landing-cards-js": () => import("./../../../src/templates/landing-cards.js" /* webpackChunkName: "component---src-templates-landing-cards-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-myth-landing-page-js": () => import("./../../../src/templates/myth-landing-page.js" /* webpackChunkName: "component---src-templates-myth-landing-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resources-blogs-blog-post-js": () => import("./../../../src/templates/resources/blogs/blog_post.js" /* webpackChunkName: "component---src-templates-resources-blogs-blog-post-js" */),
  "component---src-templates-resources-blogs-js": () => import("./../../../src/templates/resources/blogs.js" /* webpackChunkName: "component---src-templates-resources-blogs-js" */),
  "component---src-templates-resources-videos-js": () => import("./../../../src/templates/resources/videos.js" /* webpackChunkName: "component---src-templates-resources-videos-js" */)
}

